<script>
//import DeviceInfos from "./device-infos";
import DeviceInfosUpdated from "./device-infos-updated";
import { devicesMethods, devicesComputed } from "@/state/helpers";
import L from "leaflet";
import {
  LMap,
  //LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LTileLayer,
  LLayerGroup,
  LControl,
} from "vue2-leaflet";
//import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
/**
 * Map component
 */

export default {
  props: ["devices", "loader"],
  mounted() {},
  components: {
    //DeviceInfos,
    DeviceInfosUpdated,
    LMap,
    LMarker,
    LIcon,
    LTooltip,
    LTileLayer,
    LLayerGroup,
    LControl,
    //"l-marker-cluster": Vue2LeafletMarkerCluster,
  },
  data() {
    return {
      noFilter: null,
      serialFilter: null,
      filterStatus: false,
      mapZoom: 6,
      mapCenter: [27.105314, -7.684968],
      devicesPositions: [],
      eventsData: [],
      alertsData: [],

      icon: L.icon({
        iconUrl: require("@/assets/images/iconDevice.svg"),
        iconSize: [41, 41],
        iconAnchor: [16, 37],
      }),

      clusterOptions: {
        showCoverageOnHover: false,
      },

      imageDevice: require("@/assets/images/iconDevice.svg"),
      
      imageDeviceGreen: require("@/assets/images/pin_green_camion.svg"),
      imageDeviceGrey: require("@/assets/images/pin_grey_camion.svg"),
      imageDeviceBlue: require("@/assets/images/pin_blue_camion.svg"),

      imageClear: require("@/assets/images/clearmap.svg"),
      imageReset: require("@/assets/images/resetzoom.svg"),
      zoomIn: require("@/assets/images/zoomin.svg"),
      zoomOut: require("@/assets/images/zoomout.svg"),

      //url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      url: "http://{s}.google.com/vt/lyrs=m&gl=ma&x={x}&y={y}&z={z}",
      //url: "http://maps.google.com/maps?file=api&amp;v=2&amp;hl=en&amp;sensor=true",
      staticAnchor: [16, 10],
      iconAnchor: [20, 40],
      iconSize: [40, 40],
      tooltipAnchor: [0, -30],

      deviceInfos: {},
      deviceEvents: {},
      loaderDevice: false,

      typeCamion: true,
      typeUlc: true,
    };
  },
  computed: {
    ...devicesComputed,
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
  },
  methods: {
    ...devicesMethods,

    /* retrieveDevices() {
      this.getDevicesTransportBy({ province: "603e1195e2fd623f602c2440" });
    },
    showDevices({ serial }) {
      if (serial) this.getDevicesTransportBy({ serial: serial });
    }, */
    passDevice(device) {
      this.loaderDevice = true;
      let card = document.getElementById("deviceInfosCard");
      card.style.visibility = "visible";
      this.getDeviceInfos(device)
        .then((device) => {
          let params = {
            serial: device.serial,
            limit: 200,
            sortBy: "createdAt:desc",
          };
          this.getEventsBy(params).then(() => {
            this.loaderDevice = false;
          });
        })
        .catch((error) => {
          this.makeToast("Alert Error", error, "danger");
        });
    },
    search() {
      if (this.serialFilter) {
        let found = false;
        this.devicesPositions.map((obj) => {
          if (obj.serial == this.serialFilter && ((obj.province.name == 'Camion' && this.typeCamion) || (obj.province.name == 'ULC' && this.typeUlc))) {
            const mapComponent = this.$refs.map;
            let point = [obj.lat, obj.lng];
            mapComponent.fitBounds([point, point], { maxZoom: 16 });
            found = true;
          }
        });
        if(found == false) this.makeToast(this.$t("titles.device.text"), this.$t("msgError.deviceNotFound.text"), "danger")
        this.noFilter = null;
      } else {
        this.noFilter = true;
      }
    },
    changeTypeCamion() {
      this.typeCamion = !this.typeCamion 
    },
    changeTypeUlc() {
      this.typeUlc = !this.typeUlc 
    },
    setZoomIn() {
      this.mapZoom = this.mapZoom + 1;
    },
    setZoomOut() {
      this.mapZoom = this.mapZoom - 1;
    },
    setResetZoom() {
      let center = [27.105314, -7.684968];
      const mapComponent = this.$refs.map;
      let start = center;
      let end = center;
      mapComponent.fitBounds([start, end], { maxZoom: 6 });
    },
    updateZoom(event) {
      this.mapZoom = event;
    },
    /* setClearMap() {
      this.retrieveDevices();
      this.setResetZoom();
      this.clear();
    }, */
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  watch: {
    /* getDevicesTransport(newVal) {
      let positions = [...newVal.results];
      positions.map((obj) => {
        if (obj.events.length > 0) {
          return {
            ...obj,
            lat: obj.events[0].lat,
            lng: obj.events[0].lng,
          };
        }
      });
      this.devicesPositions = positions;
    },*/
    getEvents(newVal) {
      this.eventsData = newVal.results;
    },
    getDevice(newVal) {
      this.deviceInfos = newVal;
    },
    devices(newVal) {
      this.devicesPositions = newVal.filter(obj => {
        return obj.province.name == 'Camion' || obj.province.name == 'ULC'
      });
      let length = this.devicesPositions.length;
      if (length == 1) {
        const mapComponent = this.$refs.map;
        let start = [newVal[0].lat, newVal[0].lng];
        let end = [newVal[length - 1].lat, newVal[length - 1].lng];
        mapComponent.fitBounds([start, end], { maxZoom: 12 });
      }
    },
  },
  beforeDestroy() {
    //this.$refs.map.removeLayer(this.$refs.markerCluster)
  },
};
</script>

<template>
  <div>
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>

    <div style="height: 100%">
      <div
        class="searchInput mt-2"
        style="position: absolute; z-index: 999; right: 3%"
      >
        <b-input-group>
          <b-form-input
            id="serial"
            type="text"
            :placeholder="$t('textInput.enterSerial.text')"
            v-model="serialFilter"
            :state="noFilter ? false : null"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="light" class="pt-1 pb-1" @click="search">
              <i class="ri-search-line"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div
        style="
          margin-top: 210px;
          position: absolute;
          z-index: 999;
          width: 30px;
          right: 3%;
        "
      >
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="imageReset"
              @click="setResetZoom"
          /></b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomIn"
              @click="setZoomIn"
          /></b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="p-0"
            ><img
              style="cursor: pointer"
              :width="40"
              :src="zoomOut"
              @click="setZoomOut"
          /></b-col>
        </b-row>
      </div>

      <l-map
        style="height: 120vh"
        ref="map"
        :zoom="mapZoom"
        :worldCopyJump="true"
        v-on:update:zoom="updateZoom($event)"
        :min-zoom="5"
        :options="{ zoomControl: false }"
        :center="mapCenter"
      >
        <l-tile-layer :url="url" :subdomains="['mt0', 'mt1', 'mt2', 'mt3']" />
        <l-layer-group :visible="typeCamion" layerType="overlay" name="Camion">
          <l-marker
            :zIndexOffset="100"
            v-for="marker in devicesPositions.filter((obj)=>obj.province.name == 'Camion')"
            :key="marker.id"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
            @click="
              passDevice(marker.id)
            "
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageDeviceGreen" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >{{ $t("tooltips.name.text") }} :
              </span>
              {{ marker.name }}<br />
              <span style="font-weight: 550">Province : </span>
              {{ marker.province.name }} 
            </l-tooltip>
          </l-marker>
        </l-layer-group>
        <l-layer-group :visible="typeUlc" layerType="overlay" name="ULC">
          <l-marker
            :zIndexOffset="100"
            v-for="marker in devicesPositions.filter(obj => obj.province.name == 'ULC')"
            :key="marker.id"
            :draggable="false"
            :lat-lng="{ lat: marker.lat, lng: marker.lng }"
            :icon="icon"
            @click="
              passDevice(marker.id)
            "
          >
            <l-icon
              :icon-anchor="iconAnchor"
              :icon-size="iconSize"
              :tooltipAnchor="tooltipAnchor"
              :icon-url="imageDevice"
            >
              <img :src="imageDeviceBlue" />
            </l-icon>
            <l-tooltip
              class="rounded text-secondary"
              :options="{
                interactive: 'true',
                direction: 'top',
              }"
            >
              <span style="font-weight: 550"
                >{{ $t("tooltips.name.text") }} :
              </span>
              {{ marker.name }}<br />
              <span style="font-weight: 550">Province : </span>
              {{ marker.province.name }} 
            </l-tooltip>
          </l-marker>
        </l-layer-group>
        <l-control position="topleft" style="margin-top: 50%;">
          <div >
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeTypeCamion"
              ><img v-if="typeCamion" :src="imageDeviceGreen" width="40" />
              <img v-if="!typeCamion" :src="imageDeviceGrey" width="40" />Camion</span
            ><br />
            <span
              class="font-weight-bold"
              style="cursor: pointer"
              @click="changeTypeUlc"
              ><img v-if="typeUlc" :src="imageDeviceBlue" width="40" />
              <img v-if="!typeUlc" :src="imageDeviceGrey" width="40" />ULC</span
            ><br />
          </div>
        </l-control>
      </l-map>
    </div>
    <DeviceInfosUpdated
      v-bind:device="deviceInfos"
      :events="eventsData"
      :loader="loaderDevice"
    />
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 760px) {
  .searchInput {
    top: 60px;
  }
}
</style>